import style from './style.module.scss';
import { LinkBlock } from '../../components/linkBlock/linkBlock';
import superSaleImg from '../../images/discount/img.png';
import onePlusImg from '../../images/discount/img_1.png';
import weekendImg from '../../images/discount/img_2.png';
import topSaleImg from '../../images/discount/img_3.png';
import mainSaleImg from '../../images/discount/img_4.png';
import { Section } from '../../components/section/section';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Discount = () => {
	return (
		<MainTemplate>
			<Section className={ style.qualitySection } title='Акции'>
				<div className={ style.linkGroup }>
					<LinkBlock className={ style.qualityLinkBlock } href='/discount/1' bgImage={ superSaleImg } title='Супер 30%' />
					<LinkBlock className={ style.qualityLinkBlock } href='/discount/2' bgImage={ onePlusImg } title='Акция 1+' />
					<LinkBlock className={ style.qualityLinkBlock } href='/discount/3' bgImage={ weekendImg } title='Каждые выходные' />
					<LinkBlock className={ style.qualityLinkBlock } href='/discount/4' bgImage={ topSaleImg } title='Топ 21' />
					<LinkBlock className={ style.qualityLinkBlock } href='/discount/4' bgImage={ mainSaleImg } title='11' />
				</div>
			</Section>
		</MainTemplate>
	);
};
