import { MainTemplate } from '../../components/mainTemplate/mainTemplate';
import style from './style.module.scss';
import { Typography } from '../../components/typography/typography';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { withRouter } from 'react-router-dom';
import { SearchContainer } from '../../containers/searchContainer/searchContainer';
import { Catalog } from '../../components/catalog/catalog';
import { usePagination } from '../../lib/utils/hooks/usePagination';
import { useRecoilState } from 'recoil';
import { searchItemsCountAtom } from '../../recoil/atoms/searchItemsCount';
import { useURL } from '../../lib/utils/hooks/useURL';
import { useHistory } from 'react-router';

export const Search = withRouter(({ match: { params: { search: searchQuery } }, location: { search } }) => {
	const history = useHistory();

	const { getParams, addParam } = useURL(search, history);
	const { page } = getParams() || {};

	const [searchItemsCount] = useRecoilState(searchItemsCountAtom);
	const { pageCount, filter } = usePagination(40, searchItemsCount, 1);

	const handleChangePage = (page: number) => {
		window.scrollTo(0, 0);

		addParam('page')(page);
	};

	return (
		<MainTemplate>
			<Breadcrumbs>
				<Typography type='h4' tag='h1' className={ style.mainBreadcrumbText }>Поиск</Typography>
				<Typography type='body'>{ searchQuery }</Typography>
			</Breadcrumbs>
			<Catalog
				content={
					<SearchContainer search={ searchQuery } page={ +page } count={ filter } />
				}
				currentPage={ +page }
				handleChangePage={ handleChangePage }
				pageCount={ pageCount }
			/>
		</MainTemplate>
	);
});
