import { MenuNavMobile } from './menuNavMobile/menuNavMobile';
import { useRecoilState } from 'recoil';
import { isMobileAtom } from '../../recoil/atoms';
import { MenuNavDesktop } from './menuNavDesktop/menuNavDesktop';

export interface MenuNavProps {
  visible: boolean,
  onClose: () => void,
  children: JSX.Element
}

export const MenuNav = (props: MenuNavProps) => {
	const [isMobile] = useRecoilState(isMobileAtom);

	if (isMobile) return <MenuNavMobile { ...props }/>;

	return <MenuNavDesktop { ...props } />;
};
