import style from './style.module.scss';
import { Logo } from '../../logo/logo';
import { Typography } from '../../typography/typography';
import { FooterLinks } from '../footerLinks/footerLinks';
import { SocialLinks } from '../footerSocialLinks/socialLinks';
import { FooterAbout } from '../footerAbout/footerAbout';
import { FooterPayment } from '../footerPayment/footerPayment';

export const FooterMobile = (): JSX.Element => {
	return (
		<footer className={ style.footerMobile }>
			<div className={ style.container }>
				<Logo className={ style.logo } />
				<FooterAbout />
				<div className={ style.sectionGroup }>
					<FooterLinks sectionClassName={ style.section } titleClassName={ style.title } />
				</div>
				<div className={ style.socialBlock }>
					<SocialLinks className={ style.socialLink } />
				</div>
			</div>
			<div className={ style.copyright }>
				<Typography type='body'>
          			2021 – Оптово-розничный Маркетплейс © НЕДОРОГОКУПИЛИ.
				</Typography>
				<FooterPayment />
			</div>
		</footer>
	);
};
