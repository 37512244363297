import style from './style.module.scss';

interface SelectOptionGroupProps {
  label: string,
  children: JSX.Element | JSX.Element[]
}

export const SelectOptionGroup = ({ label, children }: SelectOptionGroupProps) => {
	return (
		<li className={ style.selectOptionGroup }>
			<span className={ style.label }>{ label }</span>
			<ul className={ style.selectOptions }>
				{ children }
			</ul>
		</li>
	);
};
