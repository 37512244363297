import style from './style.module.scss';
import globalHeaderStyle from '../styles/style.module.scss';
import { IconButton } from '../../iconButton/iconButton';
import { ReactComponent as HeaderOpenSVG } from '../../../images/headerOpen.svg';
import { Logo } from '../../logo/logo';
import { ReactComponent as UserSVG } from '../../../images/user.svg';
import { Badge } from '../../badge/badge';
import { ReactComponent as BucketSVG } from '../../../images/bucket.svg';
import { useHeader } from '../../../lib/utils/hooks/useHeader';
import { HeaderProps } from '../desktop/header';
import { useRecoilValue } from 'recoil';
import { basketSelector } from '../../../recoil/selectors';
import cs from 'classnames';
import { MenuNav } from '../../menuNav/menuNav';
import { ReactComponent as SearchSVG } from '../../../images/search.svg';
import { forwardRef, RefObject, useRef } from 'react';
import { SearchMenu } from '../../searchMenu/searchMenu';
import { useSearch } from '../../searchMenu/hooks/useSearch';
import { Link } from '../../link/link';
import { Icon } from '../../icon/icon';

export const MobileHeader = forwardRef(({ dark, fixed }: HeaderProps, ref): JSX.Element => {
	const { isVisibleMenu, handleMenuVisibility, closeMenuVisibility } = useHeader();
	const { length: basketLength } = useRecoilValue(basketSelector);

	const searchIcon = useRef();
	const { visibleSearch, toggleSearchVisibility, closeSearch } = useSearch();

	return (
		<>
			<MenuNav visible={ isVisibleMenu } onClose={ closeMenuVisibility }>
				<div className={ style.iconGroup }>
					<Link onClick={ closeMenuVisibility } href='/profile' type='inner' theme='default' className={ globalHeaderStyle.headerIcon }>
						<Icon image={ UserSVG } className={ globalHeaderStyle.icon } />
					</Link>
					<Badge text={ basketLength }>
						<Link onClick={ closeMenuVisibility } href='/basket' type='inner' theme='default' className={ globalHeaderStyle.headerIcon }>
							<Icon image={ BucketSVG } className={ globalHeaderStyle.icon } />
						</Link>
					</Badge>
				</div>
			</MenuNav>
			<header ref={ ref as RefObject<any> } className={ cs(globalHeaderStyle.header, style.header, { [globalHeaderStyle.headerFixed]: fixed }) } data-dark={ dark || fixed }>
				<IconButton className={ style.menuIcon } icon={ HeaderOpenSVG } onClick={ handleMenuVisibility } />
				<Logo minify={ true } className={ style.logo } />
				<IconButton ref={ searchIcon } onClick={ toggleSearchVisibility } className={ style.searchIcon } icon={ SearchSVG } />
				<SearchMenu controllerEl={ searchIcon } onClose={ closeSearch } refEl={ searchIcon } visible={ visibleSearch } />
			</header>
		</>
	);
});

MobileHeader.displayName = 'MobileHeader';
