import style from '../../pages/item/style.module.scss';
import { ChosenItem } from '../chosenItem';
import { Button } from '../button/button';
import { Modal } from '../modal/modal';
import { useRecoilValue } from 'recoil';
import { sizeSelector } from '../../recoil/selectors';
import { ButtonLink } from '../buttonLink/buttonLink';
import { createItemID } from '../../lib/utils/createItemID';
import { useBasket } from '../../lib/utils/hooks/useBasket';

interface ItemModalProps {
  	onClose: () => void,
  	visible: boolean,
  	item: {
    	id: number | undefined,
    	image: string,
		imageID: number,
    	price: number,
    	sizeID: number,
    	title: string
  	}
}

export const ItemModal = ({ onClose, visible, item }: ItemModalProps) => {
	const { image, price, sizeID, title, id, imageID } = item;
	const { sizeByID } = useRecoilValue(sizeSelector);
	const { getByUniqueID, update } = useBasket();
	const uniqueID = createItemID({ id, size: sizeID, image: imageID });

	const { count } = getByUniqueID(uniqueID) || {};

	const incrementCount = () => {
		if (count) update(uniqueID, { count: count + 1 });
	};

	const decrementCount = () => {
		if (count && count - 1 >= 1) update(uniqueID, { count: count - 1 });
	};

	return (
		<Modal onClose={ onClose } title='Успешно' subtitle='Товар добавлен в корзину' visible={ visible }>
			<div className={ style.modalBucketBody }>
				<ChosenItem onIncrease={ incrementCount } onDecrease={ decrementCount } count={ count } subtitle={ `Артикул ${id}` } characteristics={ [{ option: 'Размер', value: visible && sizeByID(sizeID as number)?.text }] } image={ image } price={ price + ' ₽' } title={ title } />
			</div>
			<div className={ style.modalButtons }>
				<Button className={ style.modalButton } theme='secondary' onClick={ onClose }>Продолжить покупки</Button>
				<ButtonLink href='/basket' className={ style.modalButton } theme='primary' type='inner'>Оформить</ButtonLink>
			</div>
		</Modal>
	);
};
