import style from './style.module.scss';
import { isKeyboardPress } from '../../lib/utils/isKeyboardPress';
import { forwardRef, LegacyRef } from 'react';
import cs from 'classnames';
import { Slider } from '../slider/slider/slider';
import { Slide } from '../slider/slide/slide';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

export interface Image {
  image: string,
  payload: any
}

interface ImagesSliderProps {
  images: Image[] | undefined,
  activeImageID?: number,
  onChoose?: (payload: any) => void,
  onClick?: () => void,
  position?: 'horizontal' | 'vertical',
  largeImageClassName?: string,
  className?: string
}

export const ImagesSlider = forwardRef(({ images, onChoose, activeImageID, onClick, className, position, largeImageClassName }: ImagesSliderProps, ref): JSX.Element => {
	const findActiveImage = images?.find((elem) => elem.payload.id === activeImageID);
	const previewImages = findActiveImage && findActiveImage.image;

	const handleKeyDown = (e: any) => isKeyboardPress(e) && onClick?.();

	return (
		<div className={ cs(style.imagesSlider, className) } data-position={ position } ref={ ref as LegacyRef<HTMLDivElement> }>
			<div className={ style.imageButton } role='button' tabIndex={ 0 } onClick={ onClick } onKeyDown={ handleKeyDown }>
				<img className={ cs(style.largeImage, largeImageClassName) } src={ previewImages } alt='main' />
			</div>
			<Slider className={ style.imageSliderBlock } position='horizontal' gap={ 20 }>
				{ images?.map((image: Image, index: number) => {
					const { image: src, payload } = image;

					const handleClick = () => onChoose?.(payload);
					const handleKeyboard = (e: any) => isKeyboardPress(e) ? onChoose?.(payload) : undefined;

					return (
						<Slide className={ style.slide } key={ index } onClick={ handleClick } onKeyDown={ handleKeyboard }>
							<img className={ cs(style.image, { [style.active]: activeImageID === index }) } src={ src } alt='item' />
						</Slide>
					);
				}) }
			</Slider>
			{ /* <slider flexSlide={ false } gap={ 20 } className={ style.imageSliderBlock } arrows={ true } slidePosition={ position }>
				{ images?.map((image: Image) => {
				  const { image: src, payload } = image;

				  imageID++;

				  const handleClick = () => onChoose?.(payload);
				  const handleKeyboard = (e: any) => (isKeyboardPress(e)) && onChoose?.(payload);

				  return (
						<slide key={ imageID } className={ style.slide } onClick={ handleClick } onKeyDown={ handleKeyboard }>
							<img className={ cs(style.image, { [style.active]: activeImageID === imageID - 1 }) } src={ src } alt='item' />
						</slide>
					);
				}) }
			</slider> */ }
		</div>
	);
});

ImagesSlider.displayName = 'ImagesSlider';

ImagesSlider.defaultProps = {
	onChoose: undefined,
	onClick: undefined,
	activeImageID: 0,
	position: 'horizontal',
	largeImageClassName: undefined,
	className: undefined
};

