import { CSSProperties, LegacyRef, RefObject } from 'react';
import style from './style.module.scss';
import { useRect } from '../../lib/utils/hooks/useRect';
import { useWindowSize } from '../../lib/utils/hooks/useWindowSize';
import { MutableRefObject } from 'react';
import cs from 'classnames';
import { useOutside } from '../../lib/utils/hooks/useOutside';

interface MenuProps {
  	children: JSX.Element | JSX.Element[],
  	refEl: MutableRefObject<any>,
  	visible: boolean,
  	className?: string,
  	onClose?: () => void,
	controllerRef?: RefObject<any>
}

export const Menu = ({ children, refEl, visible, className, onClose, controllerRef }: MenuProps) => {
	const menuRef = useOutside(() => onClose && onClose(), { capturing: true, exclude: [controllerRef!] });

	const refRect = useRect(refEl);
	const menuRect = useRect(menuRef);

	const { width: windowWidth } = useWindowSize();

	const menuLeftPosition = refRect?.left + pageXOffset - menuRect?.width / 2;
	const isMore = menuLeftPosition + menuRect?.width > windowWidth;
	const left = windowWidth - menuRect?.width;
	const top = refRect?.height + 20 + Math.abs(refRect?.top);

	const menuStyles = {
		left: isMore && !isNaN(left) ? windowWidth - menuRect?.width : !isNaN(menuLeftPosition) ? menuLeftPosition : undefined,
		top: !isNaN(top) && refRect?.height + 20 + Math.abs(refRect?.top),
		opacity: visible ? 1 : 0,
		visibility: visible ? 'visible' : 'hidden'
	};

	return (
		<div ref={ menuRef as LegacyRef<any> } style={ menuStyles as CSSProperties } className={ cs(style.menu, className) }>
			{ children }
		</div>
	);
};

Menu.defaultProps = {
	className: undefined,
	onClose: undefined,
	controllerRef: []
};
