import style from './style.module.scss';
import cs from 'classnames';

interface ChosenItemsProps {
  children: JSX.Element | JSX.Element[] | undefined,
  className?: string
}

export const ChosenItems = ({ children, className }: ChosenItemsProps): JSX.Element => {
	return (
		<div className={ cs(style.chosenItems, className) }>
			{ children }
		</div>
	);
};

ChosenItems.defaultProps = {
	className: null
};
