import { isMobile as isMobileDetect } from 'react-device-detect';
import { useEffect, useState } from 'react';

export const useDetectMobile = (): boolean => {
	const [isMobile, setMobile] = useState<boolean>(false);

	useEffect(() => {
		const detectMobile = () => {
			const { clientWidth: width } = document.documentElement;

			setMobile(isMobileDetect || width < 1024);
		};

		detectMobile();

		window.addEventListener('resize', detectMobile);

		return () => {
			window.removeEventListener('resize', detectMobile);
		};
	}, []);

	return isMobile;
};
