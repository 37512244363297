import style from './style.module.scss';
import { Typography } from '../../components/typography/typography';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Image, ImagesSlider } from '../../components/imagesSlider/imagesSlider';
import { SelectOption } from '../../components/select/selectOption/selectOption';
import { Select } from '../../components/select/select';
import { useState } from 'react';
import { Button } from '../../components/button/button';
import { withRouter } from 'react-router-dom';
import { ItemModal } from '../../components/itemModal/itemModal';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';
import { usePurchase } from '../../lib/utils/hooks/usePurchase';
import { useRecoilValue } from 'recoil';
import { sizeSelector } from '../../recoil/selectors';
import { ScaleImage } from '../../components/scaleImage/scaleImage';
import { Promt } from '../../components/promt/promt';
import { useScaleImage } from '../../components/scaleImage/hooks/useScale';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';
import { sexStatus } from '../../lib/utils/statuses';
import { ToggleVisibility } from '../../components/toggleVisibility/toggleVisibility';
import { Label } from '../../components/label/label';
import { Link as RouterLink } from 'react-router-dom';
import { Badge } from '../../components/badge/badge';

export interface InitItem {
	uniqueID?: string;
	title: string,
	description: string | undefined,
	id: number | undefined,
	sizes: number[] | undefined,
	price: number,
	images: Image[],
	is_discount: false,
	brands: string[],
	sex: number,
	categories: { text: string, categoryID: number }[]
}

export const Item = withRouter(({ match }): JSX.Element => {
	const id = +match.params.id;

	const [showModal, setShowModal] = useState<boolean>(false);
	const toggleModal = () => setShowModal(prevState => !prevState);
	const { sizeByID } = useRecoilValue(sizeSelector);

	const [showScale, toggleScale] = useScaleImage();

	const { item, chooseImage, image, fetchStatus, addItemToBasket, setSize } = usePurchase(id, toggleModal);
	const { title, itemID, price, size, images, description, sizes, brands, sex, categories, is_discount } = item;

	return (
		<MainTemplate>
			<ScaleImage images={ images } visible={ showScale } activeImage={ image } onClose={ toggleScale } />
			<Breadcrumbs className={ style.breadcrumbBlock }>
				<Typography type='h4' tag='span' className={ style.mainBreadcrumbText }>Каталог</Typography>
				<Typography type='body'>{ title as string }</Typography>
			</Breadcrumbs>
			<div className={ style.itemBlock }>
				<ItemModal onClose={ toggleModal } visible={ showModal } item={ { id: itemID, image: images[image]?.image, price, title, sizeID: size as number, imageID: image } } />
				<FetchResponse status={ fetchStatus } error='Пожалуйста, попробуйте снова поискать товар или найдите другой'>
					<ImagesSlider onClick={ toggleScale } activeImageID={ image } onChoose={ chooseImage } images={ images } />
					<div className={ style.itemMain }>
						<Typography type='body'>{ `Артикул ${id}` }</Typography>
						<Typography type='h3' tag='h1' className={ style.title }>{ title }</Typography>
						<div className={ style.priceBlock }>
							<Typography type='h4' tag='span'>{ `${price} ₽` }</Typography>
							<ToggleVisibility visible={ is_discount }>
								<Label label='Скидка 30%' className={ style.label } />
							</ToggleVisibility>
						</div>
						<div className={ style.info }>
							<div className={ style.params }>
								<div className={ style.labels }>
									{ categories?.map((category) => {
										const { text, categoryID } = category;

										return <RouterLink to={ `/items/${categoryID}` } className={ style.labelLink } key={ categoryID }><Label className={ style.label } label={ `${text}` } /></RouterLink>;
									}) }
								</div>
								<ToggleVisibility visible={ !!sizes }>
									<Select className={ style.param } onChange={ setSize } placeholder='Размер' value={ size } closeOnSelect>
										{ sizes?.map((sizeIDIteration) => {
											const sizeObject = sizeByID(sizeIDIteration);

											return <SelectOption value={ sizeIDIteration } key={ sizeIDIteration }>{ sizeObject?.text }</SelectOption>;
										}) }
									</Select>
								</ToggleVisibility>
								<Button className={ style.param } theme='primary' onClick={ addItemToBasket }>
									Добавить в корзину
								</Button>
								<Promt text='Для выбора цвета или модели нажмите на нужное изображение' />
							</div>
							<div className={ style.description }>
								<Typography type='h5' tag='h3'>Описание</Typography>
								<ul className={ style.characteristics }>
									<li>{ `Бренд: ${brands?.join(', ') ?? ''}` }</li>
									<li>{ `Пол: ${sexStatus[sex] ?? ''}` }</li>
								</ul>
								<Typography type='body' className={ style.descriptionText }>
									{ description }
								</Typography>
							</div>
						</div>
					</div>
				</FetchResponse>
			</div>
		</MainTemplate>
	);
});
