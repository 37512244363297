import style from './style.module.scss';
import cs from 'classnames';
import { forwardRef, RefObject } from 'react';

export type ButtonTheme = 'primary' | 'secondary' | 'third'

export interface GeneralButtonProps {
  children?: string | JSX.Element | JSX.Element[] | number,
  className?: string,
  onClick?: (e: any) => void,
  onClickCapture?: (e: any) => void
}

interface ButtonProps extends GeneralButtonProps {
	theme?: ButtonTheme | 'text',
}

export const generalButtonThemes = {
	primary: style.primaryButtonTheme,
	secondary: style.secondaryButtonTheme,
	third: style.thirdButtonTheme
};

const buttonThemes = {
	...generalButtonThemes,
	text: style.textButtonTheme
};

export const Button = forwardRef(({ children, theme, className, onClick, onClickCapture }: ButtonProps, ref): JSX.Element => {
	const buttonTheme = theme ? buttonThemes[theme] : undefined;

	return <button onClick={ onClick } onClickCapture={ onClickCapture } ref={ ref as RefObject<any> } tabIndex={ 0 } type='button' className={ cs(style.button, buttonTheme, className) }>{ children }</button>;
});

Button.displayName = 'Button';

Button.defaultProps = {
	className: undefined,
	onClick: undefined,
	theme: undefined,
	children: undefined
};
