import React, { StyleHTMLAttributes } from 'react';
import style from './style.module.scss';
import cn from 'classnames';

const types = {
	h1: {
		tag: 'h1',
		css: style.titleH1
	},
	h2: {
		tag: 'h2',
		css: style.titleH2
	},
	h3: {
		tag: 'h3',
		css: style.titleH3
	},
	h4: {
		tag: 'h4',
		css: style.titleH4
	},
	h5: {
		tag: 'h5',
		css: style.titleH5
	},
	h6: {
		tag: 'h6',
		css: style.titleH6
	},
	subtitle: {
		tag: 'span',
		css: style.subtitle
	},
	subtitle2: {
		tag: 'span',
		css: style.subtitle2
	},
	body: {
		tag: 'p',
		css: style.textBody
	},
	bgTitle: {
		tag: 'span',
		css: style.bgTitle
	},
	bgtTitle: {
		tag: 'span',
		css: style.bgtTitle
	}
};

interface TypographyProps {
  children: JSX.Element | JSX.Element[] | string | number | undefined,
  className?: string,
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle' | 'subtitle2' | 'body' | 'bgTitle' | 'bgtTitle',
  tag?: string,
  style?: unknown,
  gradient?: boolean,
  onClick?: (e?: any) => void,
  onKeyDown?: (e?: any) => void,
  onMouseOver?: (e?: any) => void
}

export const Typography = ({ children, className, type, tag, style, onClick, onKeyDown, gradient, onMouseOver }: TypographyProps): JSX.Element => {
	const gradientStyle = {
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',
		WebkitTextFillColor: 'transparent'
	};

	return React.createElement(
		tag || types[type].tag,
		// @ts-ignore
		{ className: cn(types[type].css, className), onClick, tabIndex: onClick ? 0 : -1, onKeyDown, onMouseOver, style: gradient ? { ...style, ...gradientStyle } : style },
		children
	);
};

Typography.defaultProps = {
	className: null,
	tag: null,
	style: null,
	gradient: false,
	onClick: null,
	onKeyDown: null,
	onMouseOver: undefined
};
