import { useMemo } from 'react';
import { parseUrlQuery } from '../urlQuery';
import { IValue } from '../../../components/select/select';

export const useURL = (search: any, history: any) => {
	const params = useMemo(() => {
		return new URLSearchParams(search);
	}, [search]);

	const getParams = () => parseUrlQuery(params);

	const updateHistory = (params: any) => {
		history.push({
			pathname: location.pathname,
			search: params.toString()
		});
	};

	const addParam = (key: string) => (value: IValue | undefined) => {
		if (value != null) {
			params.set(key, value?.toString());

			updateHistory(params);
		}
	};

	const resetParams = (exclude: string[]) => {
		const keysToDelete: string[] = [];

		params.forEach((value, key) => {
			keysToDelete.push(key);
		});

		keysToDelete.forEach((key: string) => {
			if (!exclude.includes(key)) params.delete(key);
		});

		updateHistory(params);
	};

	return { getParams, addParam, resetParams };
};
