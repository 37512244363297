import style from '../../pages/items/style.module.scss';
import { IValue, Select } from '../select/select';
import { SelectOption } from '../select/selectOption/selectOption';
import { Sizes } from '../../lib/enums/responses';
import { SelectOptionGroup } from '../select/SelectOptionGroup/selectOptionGroup';
import { Typography } from '../typography/typography';
import cs from 'classnames';
import { Input } from '../input/input';
import { useRecoilValue } from 'recoil';
import { brandSelector } from '../../recoil/selectors/brandSelector';
import { Button } from '../button/button';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface ItemsFilterProps {
    onChangeSex: (value: IValue | undefined) => void,
    onChangeMode: (value: IValue | undefined) => void,
    onChangeBrand: (value: IValue | undefined) => void,
    onChangeSize: (value: IValue | undefined) => void,
	onChangeDate: (value: IValue | undefined) => void,
	onReset: () => void,
    sex: IValue | undefined,
    mode: IValue | undefined,
    brand: IValue | undefined,
    size: IValue | undefined,
	date: IValue | undefined,
    sizes: Sizes[],
    brandsIDs: number[],
    priceFrom: any,
    priceTo: any
}

export const ItemsFilter = ({ onChangeSex, onChangeMode, onChangeBrand, onChangeSize, onChangeDate, onReset, priceFrom, priceTo, sizes, sex, mode, brandsIDs, brand, size, date }: ItemsFilterProps) => {
	const { filterByIDs } = useRecoilValue(brandSelector);

	return (
		<div className={ style.filterGroup }>
			<Select className={ style.filter } onChange={ onChangeSex } value={ sex } placeholder='Пол' closeOnSelect>
				<SelectOption value={ 1 }>Мужской</SelectOption>
				<SelectOption value={ 2 }>Женский</SelectOption>
				<SelectOption value={ 3 }>Унисекс</SelectOption>
			</Select>
			<Select className={ style.filter } onChange={ onChangeMode } value={ mode } placeholder='Стоимость' closeOnSelect>
				<SelectOption value={ 0 }>Все</SelectOption>
				<SelectOption value={ 1 }>Сначала дешевые</SelectOption>
				<SelectOption value={ 2 }>Сначала дорогие</SelectOption>
			</Select>
			<Select className={ style.filter } onChange={ onChangeDate } value={ date } placeholder='Дата' closeOnSelect>
				<SelectOption value={ 1 }>Сначала новые</SelectOption>
				<SelectOption value={ 2 }>Сначала старые</SelectOption>
			</Select>
			<Select className={ style.filter } onChange={ onChangeBrand } placeholder='Бренд' value={ brand } closeOnSelect>
				{ filterByIDs(brandsIDs)?.map((brandObject: any) => {
					const { brand, _id: id } = brandObject || {};

					return <SelectOption value={ id } key={ id }>{ brand }</SelectOption>;
				}) }
			</Select>
			<ToggleVisibility visible={ sizes?.length > 0 }>
				<Select className={ style.filter } onChange={ onChangeSize } placeholder='Размер' value={ size } closeOnSelect>
					{ sizes?.map((size: Sizes) => {
						const { sizeText, sizeID, params } = size || {};

						return (
							<SelectOptionGroup key={ sizeID } label={ sizeText }>
								{ params?.map((param) => {
									const { text, sizeID } = param;

									return <SelectOption value={ sizeID } key={ sizeID }>{ text }</SelectOption>;
								}) }
							</SelectOptionGroup>
						);
					}) }
				</Select>
			</ToggleVisibility>
			<Typography type='body' className={ style.priceText }>Цена</Typography>
			<div className={ cs(style.filter, style.priceFilter) }>
				<Input value={ priceFrom.value() } { ...priceFrom.handlers } error={ priceFrom.error } errorMsg='Некорректная цена' className={ style.priceField } type='text' placeholder='От'/>
				<Input value={ priceTo.value() } { ...priceTo.handlers } className={ style.priceField } error={ priceTo.error } errorMsg='Некорректная цена' type='text' placeholder='До'/>
			</div>
			<Button theme='primary' onClick={ onReset }>Сбросить</Button>
		</div>
	);
};
