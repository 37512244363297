import style from './style.module.scss';

interface SelectFieldProps {
  onClick?: (e: any) => void,
  onKeyDown?: (e: any) => void,
  children?: string | null,
  placeholder: string
}

export const SelectField = ({ onClick, onKeyDown, children, placeholder }: SelectFieldProps): JSX.Element => {
	return (
		<div className={ style.selectField } onClick={ onClick } onKeyDown={ onKeyDown } tabIndex={ 0 } role='button'>
			{ children ? children : <span className={ style.placeholder }>{ placeholder }</span> }
			<svg className={ style.arrow } viewBox="0 0 8 10" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.41676 0.141754L4.58343 0.141754L4.58343 7.62494L6.4749 5.73346L7.29986 6.55842L4.00003 9.85825L0.700195 6.55842L1.52515 5.73346L3.41676 7.62507L3.41676 0.141754Z"/>
			</svg>
		</div>
	);
};

SelectField.defaultProps = {
	onClick: null,
	onKeyDown: null,
	children: null
};
