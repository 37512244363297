import { selector } from 'recoil';
import { itemBrandsAtom } from '../atoms';

export const brandSelector = selector({
	key: 'brandSelector',
	get: ({ get }) => {
		const brands = get(itemBrandsAtom);

	  	const filterAlphabetically = () => {
			return brands.sort((a: any, b: any) => {
				if (a.brand < b.brand) return -1;
				if (a.brand > b.brand) return 1;

				return 0;
			});
		};

		const filterByIDs = (ids: number[]) => {
			return ids?.length === 0 ? brands : brands?.filter((brand: any) => ids?.includes(brand._id));
		};

		return { filterByIDs, filterAlphabetically };
	}
});
