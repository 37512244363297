import style from './style.module.scss';
import { Typography } from '../../typography/typography';
import { Modal } from '../../modal/modal';
import { IOrderAdminFetchResponse } from '../../../lib/api/rest/admin/orders';
import { ChosenItems } from '../../chosenItems/chosenItems';
import { ChosenItem } from '../../chosenItem';
import { deliveryStatus, paymentStatus } from '../../../lib/utils/statuses';
import { useRecoilValue } from 'recoil';
import { sizeSelector } from '../../../recoil/selectors';

interface ModalOrderProps {
  visible: boolean,
  onClose: () => void,
  order: IOrderAdminFetchResponse | undefined
}

export const ModalOrder = ({ visible, onClose, order } : ModalOrderProps) => {
	const { customer, totalprice, delivery_time, pickup_date, method, deliverymethod, ispayed, created_at, items } = order || {};
	const { email, phone, street, house, flat, index, city } = customer || {};
	const { sizeByID } = useRecoilValue(sizeSelector);

	const priceWithoutDiscount = items?.reduce((acc, cur) => acc + (cur.price * cur.count), 0);

	return (
		<Modal onClose={ onClose } className={ style.modal } title='Заказ' subtitle='Информация' visible={ visible }>
			<div className={ style.modalBody }>
				<ul className={ style.ul }>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Email:</Typography><Typography type='body' tag='span' className={ style.liText }>{ email }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Телефон:</Typography><Typography type='body' tag='span' className={ style.liText }>{ phone }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Город:</Typography><Typography type='body' tag='span' className={ style.liText }>{ city }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Индекс:</Typography><Typography type='body' tag='span' className={ style.liText }>{ index }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Улица:</Typography><Typography type='body' tag='span' className={ style.liText }>{ street }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Дом:</Typography><Typography type='body' tag='span' className={ style.liText }>{ house }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Квартира:</Typography><Typography type='body' tag='span' className={ style.liText }>{ flat }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Сумма:</Typography><Typography type='body' tag='span' className={ style.liText }>{ `${totalprice} ₽` }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Сумма без скидки:</Typography><Typography type='body' tag='span' className={ style.liText }>{ `${priceWithoutDiscount} ₽` }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Время доставки:</Typography><Typography type='body' tag='span' className={ style.liText }>{ delivery_time }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Время самовывоза:</Typography><Typography type='body' tag='span' className={ style.liText }>{ pickup_date }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Оплата:</Typography><Typography type='body' tag='span' className={ style.liText }>{ ispayed ? 'Да' : 'Нет' }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Метод оплаты:</Typography><Typography type='body' tag='span' className={ style.liText }>{ method && paymentStatus[method] }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Статус доставки:</Typography><Typography type='body' tag='span' className={ style.liText }>{ deliverymethod && deliveryStatus[deliverymethod] }</Typography></li>
					<li className={ style.li }><Typography type='body' tag='span' className={ style.liTitle }>Дата заказа:</Typography><Typography type='body' tag='span' className={ style.liText }>{ created_at && new Date(created_at).toLocaleDateString() }</Typography></li>
				</ul>
				<ChosenItems>
					{ items?.map((item) => {
						const { count, id, name, image_url, size, price } = item;
						console.log(price);
						return (
							<ChosenItem id={ id } key={ id } className={ style.item } price={ `${price} ₽` } title={ name } subtitle={ `Артикул ${id}` } image={ image_url } characteristics={
								[
									{
										option: 'Размер',
										value: sizeByID(size)?.text
									},
									{
										option: 'Количество',
										value: count
									}
								]
							}
							/>
						);
					}) }
				</ChosenItems>
			</div>
		</Modal>
	);
};
