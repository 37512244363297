import { request } from '../request';

interface ISearchFetchRequest {
	query: string,
	count?: number,
	offset?: number,
	mode?: number
}

export const searchFetch = (query: ISearchFetchRequest) => {
	return request({ url: '/search', params: query });
};
