import style from './style.module.scss';
import { cloneElement } from 'react';
import cs from 'classnames';
import { Children } from 'react';

interface NavProps {
  children: JSX.Element|JSX.Element[] | undefined,
  orientation: 'vertical' | 'horizontal',
  className?: string
}

export const Nav = ({ children, orientation, className }: NavProps): JSX.Element => {
	let id = 0;

	const navLinks = children && Children.map(children, (navLink: JSX.Element) => {
		id++;
		return navLink && cloneElement(navLink, { className: cs(style.navigationLink, navLink.props.className), key: id });
	});

	return <nav className={ cs(style.navigation, className) } data-orientation={ orientation }>{ navLinks }</nav>;
};

Nav.defaultProps = {
	className: null
};
