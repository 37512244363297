import { Typography } from '../typography/typography';
import { KeyboardEvent } from 'react';
import cs from 'classnames';
import style from './style.module.scss';
import { isKeyboardPress } from '../../lib/utils/isKeyboardPress';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface PaginationProps {
  	currentPage: number | undefined,
  	pageCount: number,
  	filter?: number,
  	onChange?: (page: number) => void | undefined,
  	className?: string,
	defaultPage?: number
}

export const Pagination = ({ currentPage, pageCount, filter, onChange, className, defaultPage }: PaginationProps): JSX.Element | null => {
	if (!pageCount || pageCount === 0) return null;

	let paginationID = 0;
	const length = filter ? filter && filter > pageCount ? pageCount : filter : 0;


	const pageIndex = Array.from({ length }, (_, index) => {
	  // @ts-ignore
		const page = currentPage >= filter ? pageCount - filter < currentPage - 1 ? pageCount - filter + index + 1 : currentPage + index - 2 : index + 1;
	  	const clickPage = () => onChange && onChange(page);

	  	paginationID++;

	  	const onKeyDown = (e: KeyboardEvent) => (e.code === 'Enter' || e.code === 'Space') && onChange && onChange(page);
		return <Typography key={ paginationID } type='body' onClick={ clickPage } onKeyDown={ onKeyDown } className={ cs(style.text, { [style.active]: isNaN(currentPage as number) ? index + 1 === defaultPage : page === currentPage }) }>{ page }</Typography>;
	});

	const lastPage = () => onChange && onChange(pageCount);
	const firstPage = () => onChange && onChange(1);

	const handleLastPageKeyboard = (e: KeyboardEvent) => isKeyboardPress(e) && lastPage();
	const handleFirstPageKeyboard = (e: KeyboardEvent) => isKeyboardPress(e) && firstPage();

	const nextPage = () => currentPage && currentPage + 1 <= pageCount && onChange && onChange(currentPage + 1);
	const nextPageKeyboard = (e: KeyboardEvent) => isKeyboardPress(e) && nextPage();
	const prevPage = () => currentPage && currentPage - 1 > 0 && onChange && onChange(currentPage - 1);
	const prevPageKeyboard = (e: KeyboardEvent) => isKeyboardPress(e) && prevPage();

	return (
		<div className={ cs(style.pagination, className) }>
			<svg className={ style.arrow } onClick={ prevPage } onKeyDown={ prevPageKeyboard } tabIndex={ 0 } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.2431 6.34317L14.8288 4.92896L7.75781 12L14.8289 19.0711L16.2431 17.6569L10.5862 12L16.2431 6.34317Z"/>
			</svg>
			<ToggleVisibility visible={ !!currentPage && !!filter && pageCount !== filter && currentPage >= filter }>
				<Typography onClick={ firstPage as any } onKeyDown={ handleFirstPageKeyboard } type='body' className={ cs(style.text, { [style.active]: 1 === currentPage }) }>{ 1 + '...' }</Typography>
			</ToggleVisibility>
			{ pageIndex }
			<ToggleVisibility visible={ !!currentPage && !!filter && currentPage >= filter && pageCount - filter + 1 > currentPage - 1 }>
				<Typography onClick={ lastPage as any } onKeyDown={ handleLastPageKeyboard } type='body' className={ cs(style.text, { [style.active]: pageCount === currentPage }) }>{ '...' + pageCount }</Typography>
			</ToggleVisibility>
			<svg className={ style.arrow } onClick={ nextPage } onKeyDown={ nextPageKeyboard } tabIndex={ 0 } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10.5859 6.34314L12.0002 4.92892L19.0712 12L12.0002 19.0711L10.5859 17.6568L16.2428 12L10.5859 6.34314Z"/>
			</svg>
		</div>
	);
};

Pagination.defaultProps = {
	filter: 5,
	className: null,
	onChange: undefined,
	defaultPage: 1
};
