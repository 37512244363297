import style from './style.module.scss';
import cs from 'classnames';
import { Typography } from '../typography/typography';
import { Link } from 'react-router-dom';

interface CategoryBlockProps {
  	href: string,
  	title: string,
  	bgImage: string,
  	className?: string,
	size?: 'large' | 'small'
}

const sizes: Record<string, string> = {
	'small': style.smallLinkBlock,
	'large': style.largeLinkBlock
};

export const LinkBlock = ({ href, title, bgImage, className, size }: CategoryBlockProps): JSX.Element => {
	const LinkBlockStyle = {
		background: `url(${bgImage}) no-repeat center/cover`
	};

	const sizeClass = size ? sizes[size] : undefined;

	return (
		<Link className={ cs(style.linkBlock, sizeClass, className) } to={ href } style={ LinkBlockStyle }>
			<Typography type='h3'>{ title }</Typography>
		</Link>
	);
};

LinkBlock.defaultProps = {
	className: null,
	size: 'large'
};
