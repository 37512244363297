import { Typography } from '../typography/typography';
import style from './style.module.scss';
import cs from 'classnames';

interface SectionTitleProps {
  children: string,
  className?: string
}

export const SectionTitle = ({ children, className }: SectionTitleProps): JSX.Element => {
	return <Typography type='h2' className={ cs(style.sectionTitle, className) }>{ children }</Typography>;
};

SectionTitle.defaultProps = {
	className: null
};
