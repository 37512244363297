import storage from '../storage';
import { IValue } from '../../../components/select/select';

export interface BasketItem {
    id: number
    size: IValue,
    image: number | undefined
    count: number,
    price: number,
    imageURL: string | undefined,
    name: string,
    is_discount: boolean,
    uniqueID?: string,
    categories?: number[]
}

export interface Basket {
    cart: BasketItem[],
    modified_date: Date
}

export const getBasket = async (): Promise<Basket | null> => {
	return JSON.parse(await storage.get('basket'));
};

export const setBasket = (value: any): Promise<void> => {
	return storage.set('basket', JSON.stringify(value));
};

export const clearBasket = (): Promise<void> => {
	return storage.remove('basket');
};