import style from './style.module.scss';
import cs from 'classnames';
import { TextareaHTMLAttributes } from 'react';

interface TextareaProps extends TextareaHTMLAttributes<any> {
  placeholder?: string,
  className?: string
}

export const Textarea = ({ placeholder, className, onInput }: TextareaProps): JSX.Element => {
	return <textarea className={ cs(style.textarea, className) } onInput={ onInput } placeholder={ placeholder } />;
};

Textarea.defaultProps = {
	placeholder: null,
	className: null
};
