import style from './style.module.scss';
import { LinkBlock } from '../../components/linkBlock/linkBlock';
import { Section } from '../../components/section/section';

import acce from '../../images/quality/acce.jpg';
import watch from '../../images/quality/watch.jpg';
import handbags from '../../images/quality/handbags.jpg';
import perfumery from '../../images/quality/perfumery.jpg';
import clothes from '../../images/quality/clothes.jpg';
import homeClothes from '../../images/quality/homeclothes.jpg';
import underwear from '../../images/quality/Underwear.jpg';
import shoes from '../../images/quality/shoes.jpg';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Quality = (): JSX.Element => {
	return (
		<MainTemplate>
			<Section className={ style.qualitySection } title='Качество'>
				<div className={ style.linkGroup }>
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/1' bgImage={ acce } title='Аксессуары' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/2' bgImage={ watch } title='Часы' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/3' bgImage={ handbags } title='Сумки' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/4' bgImage={ perfumery } title='Парфюмерия' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/5' bgImage={ clothes } title='Одежда' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/6' bgImage={ homeClothes } title='Домашняя одежда' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/7' bgImage={ underwear } title='Нижнее белье' />
					<LinkBlock size='small' className={ style.qualityLinkBlock } href='/quality/8' bgImage={ shoes } title='Обувь' />
				</div>
			</Section>
		</MainTemplate>
	);
};
