import style from './style.module.scss';
import cs from 'classnames';

interface RadioButtonProps {
  checked?: boolean
}

export const RadioButton = ({ checked }: RadioButtonProps): JSX.Element => {
	return (
		<div className={ cs(style.radioButton) } data-checked={ checked } role='button' tabIndex={ 0 }>
			<svg className={ style.mark } viewBox="0 0 12 10" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
				<path d="M4.58578 6.41421L1.75735 3.58578L0.34314 5L4.58578 9.24264L11.6568 2.17157L10.2426 0.757355L4.58578 6.41421Z"/>
			</svg>
		</div>
	);
};

RadioButton.defaultProps = {
	checked: false
};
