import { useState, Children, useRef, cloneElement, MutableRefObject, StyleHTMLAttributes, ReactElement, useEffect, useCallback } from 'react';
import { SliderTrack } from '../sliderTrack/sliderTrack';
import { useRect } from '../../../lib/utils/hooks/useRect';
import { useInterval } from '../../../lib/utils/hooks/useInterval';
import { ISlide } from '../slide/slide';
import React from 'react';
import style from './style.module.scss';
import cs from 'classnames';
import { ToggleVisibility } from '../../toggleVisibility/toggleVisibility';
import { Dots } from '../dots/dots';

interface ISlider {
  children: ReactElement<ISlide> | ReactElement<ISlide>[] | undefined;
  position: 'vertical' | 'horizontal';
  autoplay?: boolean;
  autoplayDuration?: number;
  loop?: boolean;
  gap?: number;
  className?: string;
  arrows?: boolean;
  dots?: boolean;
}

export const Slider = ({ children, position, autoplay, autoplayDuration, dots, loop, gap, className, arrows }: ISlider) => {
	const [slide, setSlide] = useState(0);
	const count = Children.count(children);

	const currentSlideRef = useRef() as MutableRefObject<HTMLDivElement>;
	const trackRef = useRef() as MutableRefObject<HTMLDivElement>;
	const sliderRef = useRef() as MutableRefObject<HTMLDivElement>;

	const rect = useRect(currentSlideRef);
	const transform = slide * (position === 'horizontal' ? rect?.width : rect?.height) + (gap as number) * slide;

	const limitsObject = {
		x: trackRef.current?.clientWidth - sliderRef.current?.clientWidth,
		y: trackRef.current?.clientHeight - sliderRef.current?.clientHeight
	};

	const overlimitObject = {
		x: transform > limitsObject.x,
		y: transform > limitsObject.y
	};

	const touchStartX = useRef(0);
	const touchStartY = useRef(0);

	const overlimit = position === 'horizontal' ? overlimitObject.x : overlimitObject.y;

	const limit = position === 'horizontal' ? limitsObject.x : limitsObject.y;

	const transformStyle = overlimit ? limit : transform;

	trackRef?.current?.style.setProperty('--gap', `${gap}px`);

	const slides = Children.map(children, (child, index) => {
		return (// @ts-ignore
			child && cloneElement<ISlide, HTMLDivElement>(child, {
      			ref: slide === index ? currentSlideRef : undefined
			})
		);
	});

	const next = () => {
		if (slide < count - 1 && !overlimit) setSlide((prevState) => prevState + 1);

		if (overlimit && loop) setSlide(0);
	};

	const prev = () => {
		if (slide > 0) setSlide((prevState) => prevState - 1);
	};

	const positionStyle = {
		flexDirection: position === 'horizontal' ? 'row' : 'column'
	};

	useInterval(
		() => next(),
		autoplay && autoplayDuration ? autoplayDuration : null
	);

	const touchEndHandler = useCallback((e: any) => {
		const { clientX, clientY } = e.changedTouches[0] || {};

		const startX = touchStartX.current;
		const startY = touchStartY.current;

		const difference = position === 'horizontal' ? clientX - startX : clientY - startY;

		if (difference < -15) {
			next();
		} else if (difference > 15) {
			prev();
		}
	}, [next, prev]);

	const touchStartHandler = (e: any) => {
		const { clientX, clientY } = e.touches[0] || {};

		touchStartX.current = clientX ?? 0;
		touchStartY.current = clientY ?? 0;
	};

	useEffect(() => {
		document.addEventListener('touchstart', touchStartHandler.bind(this));

		return () => document.removeEventListener('touchstart', touchStartHandler.bind(this));
	}, []);

	const handleDotClick = (index: number) => setSlide(index);

	return (
		<div onTouchEnd={ touchEndHandler } className={ cs(style.sliderContainer, className) }>
			<ToggleVisibility visible={ !!arrows && slide > 0 }>
				<svg onClick={ prev } className={ cs(style.arrow, style.leftArrow) } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.4141 17.6569L11.9998 19.0711L4.92878 12L11.9998 4.92894L13.4141 6.34315L7.75721 12L13.4141 17.6569Z" />
				</svg>
			</ToggleVisibility>
			<div className={ style.slider } ref={ sliderRef } style={ positionStyle as StyleHTMLAttributes<HTMLDivElement> }>
				<SliderTrack position={ position } transform={ transformStyle } ref={ trackRef }>
					{ slides }
				</SliderTrack>
				<ToggleVisibility visible={ !!dots }>
					<Dots count={ count } onClick={ handleDotClick } activeIndex={ slide } />
				</ToggleVisibility>
			</div>
			<ToggleVisibility visible={ !!arrows && (!!loop || !overlimit) }>
				<svg onClick={ next } className={ cs(style.arrow, style.rightArrow) } viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path d="M10.5859 6.34314L12.0002 4.92892L19.0712 12L12.0002 19.0711L10.5859 17.6568L16.2428 12L10.5859 6.34314Z"/>
				</svg>
			</ToggleVisibility>
		</div>
	);
};

Slider.defaultProps = {
	autoplay: false,
	autoplayDuration: 3000,
	loop: true,
	gap: 0,
	arrows: true,
	dots: false,
	className: undefined
};
