import { useState } from 'react';
import style from './style.module.scss';
import { Map } from '../map/map';
import { ShopInfo } from '../shopInfo/shopInfo';
import { Button } from '../button/button';
import cs from 'classnames';
import { ReactComponent as CatAndCatSVG } from '../../images/catandcat.svg';

interface Geo {
  mapURL: string | undefined,
  address: string | undefined,
  mobile: string | undefined,
  workTime: string | undefined,
  image: any
}

interface GeoPickerProps {
  className?: string,
  geo: Geo[]
}

let geoID = 0;

export const GeoPicker = ({ className, geo }: GeoPickerProps): JSX.Element => {
	const defaultMap = geo[0].mapURL;

	const [map, setMap] = useState<string>(defaultMap ?? '');

	const geoOptions = geo.map(({ address, mapURL, mobile, workTime, image: Image }): JSX.Element | JSX.Element[] => {
	  geoID++;

	  const chooseMap = () => mapURL && setMap(mapURL);

	  return (
			<div key={ geoID } className={ style.optionContainer }>
				<div key={ geoID } className={ style.option }>
					{ <Image /> }
					<ShopInfo address={ address } mobile={ mobile } workTime={ workTime } />
					<Button theme='secondary' onClick={ chooseMap }>Карта</Button>
				</div>
			</div>
		);
	});

	return (
		<div className={ cs(style.geoPicker, className) }>
			<Map mapURL={ map } />
			<div className={ style.optionGroup }>
				{ geoOptions }
			</div>
		</div>
	);
};

GeoPicker.defaultProps = {
	className: null
};
