import { useRecoilState } from 'recoil';
import { userBasketAtom } from '../../../recoil/atoms';
import { BasketItem } from '../../storage/adapters/basket.adapter';
import { createItemID } from '../createItemID';

export const useBasket = () => {
	const [userBasket, setUserBasket] = useRecoilState(userBasketAtom);

	const reset = () => {
		setUserBasket([]);
	};

	const getByUniqueID = (uniqueID: string | undefined): BasketItem | undefined => {
		return userBasket?.find((item) => item.uniqueID === uniqueID);
	};

	const getIndexByUniqueID = (uniqueID: string | undefined): number | undefined => {
		return userBasket?.findIndex((item) => item.uniqueID === uniqueID);
	};

	const remove = (uniqueID: string | undefined) => {
		setUserBasket(userBasket?.filter((item) => item.uniqueID !== uniqueID));
	};

	const update = (uniqueID: string | undefined, updateObject: Record<string, any>) => {
		const index = getIndexByUniqueID(uniqueID);

		if (index != null && index !== -1 && userBasket) {
			const updatedArray = [...userBasket as BasketItem[]];
			// @ts-ignore
			updatedArray[index] = { ...getByUniqueID(uniqueID), ...updateObject };
			setUserBasket(updatedArray);
		}
	};

	const addMultiple = (items: BasketItem[]) => {
		items.forEach((item) => add(item));
	};

	const add = (item: BasketItem) => {
		const { id, image, size } = item || {};

		// TODO: Генерировать UniqueID на сервере

		const uniqueItemID = createItemID({ id, size: size as number, image: image as number });

		const { uniqueID, count, ...foundItem } = getByUniqueID(uniqueItemID) || {};

		if (!uniqueID) {
			setUserBasket(prevState => ([...prevState || [], { ...item, uniqueID: uniqueItemID }]));

			// if the item has already been added to the basket -> update count
		} else if (uniqueID && count) {
			update(uniqueID, { count: count + 1 });
		}
	};

	return {
	  reset, add, basket: userBasket, remove, update, addMultiple, getByUniqueID
	};
};
