import { ItemsGroup } from '../../components/itemGroup/itemGroup';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { itemsCount } from '../../recoil/atoms';
import { IValue } from '../../components/select/select';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';
import { useFetchStatus } from '../../lib/utils/hooks/useFetchStatus';
import { itemsFetch } from '../../lib/api';
import { FetchStatus } from '../../lib/enums/fetchStatuses';

interface Price {
  from: number | undefined ,
  to: number | undefined
}

interface ItemsFetchProps {
  	className?: string,
  	count: number,
  	page: number,
  	brand?: number | number[] | IValue | undefined,
  	size?: number | number[] | IValue | undefined,
  	price?: Price,
  	sex?: IValue | undefined,
  	category?: number,
  	mode: number | undefined,
	data: number
}

export const ItemsContainer = ({ className, count, page, size, brand, price, sex, category, mode, data }: ItemsFetchProps): JSX.Element => {
	const [_, setCountItems] = useRecoilState(itemsCount);
	const [items, setItems] = useState([]);
	const [fetchStatus, setFetchStatus] = useFetchStatus();

	useEffect(() => {
		itemsFetch({
			count,
			offset: (page - 1) * count,
			pFrom: price?.from,
			pTo: price?.to,
			size, brand, sex, category, mode, new: data
		}).then((res) => {
			const { items, count } = res.data;

			setFetchStatus(items ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);
			setItems(items);
			setCountItems(count);
		}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
	}, [page, price?.from, price?.to, brand, size, sex, category, mode, count, data]);

	return (
		<FetchResponse status={ fetchStatus } error='К сожалению, мы не смогли найти товары. Возможно, из просто не добавили или попробуйте изменить условия поиска'>
			<ItemsGroup className={ className } items={ items } />
		</FetchResponse>
	);
};

ItemsContainer.defaultProps = {
	className: null,
	loaderLineClassName: null,
	count: 10,
	page: 0,
	price: null,
	size: null,
	sex: null,
	brand: null,
	category: undefined
};
