import { Typography } from '../typography/typography';
import style from './style.module.scss';
import { Link } from '../link/link';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface NotFoundBlockProps {
  visibility: boolean,
  title: string,
  description: string
}

export const FetchErrorBlock = ({ visibility, title, description }: NotFoundBlockProps) => {
	return (
		<ToggleVisibility visible={ visibility }>
			<div className={ style.notFound }>
				<Typography type='h3'>{ title }</Typography>
				<Typography type='body' className={ style.notFoundText }>{ description }</Typography>
			</div>
		</ToggleVisibility>
	);
};
