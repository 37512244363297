import { Typography } from '../../typography/typography';
import { IconButton } from '../../iconButton/iconButton';
import style from './style.module.scss';
import cs from 'classnames';
import { ReactComponent as MinusSVG } from '../../../images/minus.svg';
import { ReactComponent as PlusSVG } from '../../../images/plus.svg';
import { ChosenItemProps } from '../desktopChosenItem/chosenItem';
import { Button } from '../../button/button';
import { Link } from '../../link/link';
import { Label } from '../../label/label';
import { ToggleVisibility } from '../../toggleVisibility/toggleVisibility';

export const MobileChosenItem = ({ image, title, subtitle, characteristics, onIncrease, onDecrease, isDiscount, id, count, price, className, onDelete }: ChosenItemProps): JSX.Element => {
	let characteristicID = 0;

	return (
		<div className={ cs(style.mobileChosenItem, className) }>
			<img className={ style.image } src={ image } alt='item' />
			<div className={ style.head }>
				<Link size='medium' href={ `/item/${id}` } type='inner' theme='default'>
					<Typography type='h4' tag='h3' className={ style.title }>{ title }</Typography>
				</Link>
				<Typography type='body' tag='span'>{ subtitle }</Typography>
				<ToggleVisibility visible={ !!isDiscount }>
					<Label label='Скидка 30%' className={ style.label } />
				</ToggleVisibility>
			</div>
			<div className={ style.itemDescription }>
				<ul className={ style.characteristics }>
					{ characteristics.map(({ option, value }) => {
						characteristicID++;

						return (value != null && value != 0) && <li key={ characteristicID } className={ style.characteristic }><strong>{ option }:</strong> { value }</li>;
					}) }
				</ul>
				{ price && <Typography type='h4' tag='span' className={ style.price }>{ price }</Typography> }
				{ count != null && <div className={ style.itemOptions }>
					<IconButton iconClassName={ style.subtractIcon } onClick={ onDecrease } size='medium' icon={ MinusSVG } />
					<Typography type='h5' tag='span' className={ style.option }>{ count }</Typography>
					<IconButton iconClassName={ style.addIcon } onClick={ onIncrease } size='medium' icon={ PlusSVG } />
				</div> }
				{ onDelete && <Button theme='secondary' onClick={ onDelete } className={ style.buttonDelete }>Удалить</Button> }
			</div>
		</div>
	);
};

MobileChosenItem.defaultProps = {
	className: null,
	onDelete: null,
	count: null,
	isDiscount: false
};
