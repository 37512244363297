import style from './style.module.scss';
import { Children, cloneElement } from 'react';
import cs from 'classnames';

interface BreadcrumbsProps {
  children: JSX.Element | JSX.Element[],
  className?: string
}

export const Breadcrumbs = ({ children, className }: BreadcrumbsProps): JSX.Element => {
	const breadcrumbsText = Children.map(children, (elem) => {
	  if (!elem.props?.children) return null;

	  return cloneElement(elem, { className: cs(style.breadcrumbText, elem.props.className) });
	});

	return (
		<div className={ cs(style.breadcrumbs, className) }>
			{ breadcrumbsText }
		</div>
	);
};

Breadcrumbs.defaultProps = {
	className: null
};
