import React, { forwardRef } from 'react';
import style from './style.module.scss';
import { Button, GeneralButtonProps } from '../button/button';
import { Icon, IconSize } from '../icon/icon';
import cs from 'classnames';

interface IconButtonProps extends GeneralButtonProps, IconSize {
  icon: any,
  className?: string,
  onClick?: (e: React.MouseEvent) => void,
  size?: 'medium' | 'small' | 'large',
  iconClassName?: string
}

export const IconButton = forwardRef(({ icon: image, className, onClick, size, iconClassName }: IconButtonProps, ref): JSX.Element => {
	return (
		<Button ref={ ref } className={ cs(className, style.iconButton) } onClick={ onClick }>
			<Icon className={ cs(style.icon, iconClassName) } image={ image } size={ size }/>
		</Button>
	);
});

IconButton.displayName = 'IconButton';

IconButton.defaultProps = {
	className: undefined,
	onClick: undefined,
	size: 'small',
	iconClassName: undefined
};
