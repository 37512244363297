import { selector } from 'recoil';
import { itemCategoriesAtom, itemSizesAtom, userBasketAtom } from './atoms';
import { SizeObject, Sizes } from '../lib/enums/responses';
import { IValue } from '../components/select/select';
import memoize from 'lodash.memoize';

export const sizeSelector = selector({
	key: 'sizeSelector',
	get: ({ get }) => {
		const sizes = get(itemSizesAtom);

		const sizeByID = (id: IValue | undefined) => {
			const sizesParams: SizeObject[] = sizes?.map((sizes: Sizes) => sizes.params).flat();
			return sizesParams?.find((size: SizeObject) => size.sizeID === id);
		};

		return { sizeByID };
	}
});

export const basketSelector = selector({
	key: 'basketSelector',
	get: ({ get }) => {
	  	const basket = get(userBasketAtom);

	  	// Полная сумма (и 30%)
		const totalPrice = basket?.reduce((acc, cur) => acc + (cur.count * cur.price), 0) ?? 0;

		// Количество товаров
		const length = basket?.length;

		// Корзина без товаров 30%, 21%, 11%
		const itemsWithoutDiscount = basket?.filter((item) => !item.is_discount);
		const itemsWithDiscount = basket?.filter((item) => item.is_discount);

		//Стоимость максимального товара в корзине
		const maxPriceInBasket = Math.max(...basket?.map((item) => item.price) ?? [0]);

		const discount = (paymentType: string | string[] | number | number[] | null | undefined): { type?: number, sum: number } | undefined => {
			// Стомость товаров с 30%, 21%, 11% скидкой
			const totalPriceWithDiscountItems = itemsWithDiscount?.reduce((acc, cur) => acc + (cur.count * cur.price), 0) ?? 0;
			// Разница между корзиной полной и корзиной только с 30%, 21%, 11% скидкой

			const countItemsWithoutDiscount = itemsWithoutDiscount?.reduce((acc, cur) => acc + cur.count, 0) ?? 0;

			const difference = Math.ceil(totalPrice - totalPriceWithDiscountItems);

			if (totalPrice >= 4500 && Math.ceil(totalPrice - maxPriceInBasket) > 1000) {
				return { sum: Math.ceil((difference * 0.79) + totalPriceWithDiscountItems), type: 1 };
			} else if (totalPrice < 4500) {
				if (paymentType !== 1) {
					return { sum: Math.ceil(difference * 0.89 + totalPriceWithDiscountItems), type: 2 };
				} else if (length && length >= 2) {
					return { sum: Math.ceil((difference - (50 * countItemsWithoutDiscount)) + totalPriceWithDiscountItems), type: 3 };
				} else {
					return { sum: totalPrice };
				}
			} else if (maxPriceInBasket >= 4500) {
				if (Math.ceil(totalPrice - maxPriceInBasket) < 1000 && paymentType !== 1) {
					return { sum: Math.ceil(difference * 0.89 + totalPriceWithDiscountItems), type: 4 };
				} else if (Math.ceil(totalPrice - maxPriceInBasket) < 1000 && paymentType === 1) {
					return { sum: Math.ceil((difference - (50 * countItemsWithoutDiscount)) + totalPriceWithDiscountItems), type: 5 };
				} else if (totalPrice >= maxPriceInBasket + 1000) {
					return { sum: Math.ceil(((difference - maxPriceInBasket) * 0.79) + totalPriceWithDiscountItems), type: 6 };
				}
			} else {
				return { sum: totalPrice };
			}
		};

		const hasDiscountItems = (itemsWithDiscount?.length ?? 0) > 0;
		const basketCategories = basket?.map((item) => item.categories).flat();

		const hasCategories = (categories: number[]) => (basketCategories?.filter((category) => category && categories.includes(category))?.length ?? 0) > 0;

		return { length, totalPrice, discount, hasDiscountItems, hasCategories };
	}
});

export const categorySelector = selector({
	key: 'categorySelector',
	get: ({ get }) => {
		const categories = get(itemCategoriesAtom);

		const searchCategory = (search: string | undefined) => {
			return categories?.filter((category) => category.subcategories?.find((subcategory) => subcategory.text.toLowerCase().search(search?.toLowerCase() as string) !== -1));
		};

		const searchBySubcategory = memoize((search: string | undefined) => {
		  return search ? searchCategory(search)?.map((category) => {
				return ({
					...category,
					subcategories: category.subcategories?.filter((subcategory) => search && subcategory.text.toLowerCase().search(search.toLowerCase()) !== -1)
				});
			}) : categories;
		});

		const findSubcategoryByID = memoize((id: number | undefined) => {
		  	const subcategories = categories?.map((category) => category.subcategories).flat();

			return subcategories?.find((subcategory) => subcategory?.categoryID === id);
		});

		return { searchCategory, findSubcategoryByID, searchBySubcategory };
	}
});
