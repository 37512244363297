import { Image, ImagesSlider } from '../imagesSlider/imagesSlider';
import { SetStateAction, useEffect, useState } from 'react';
import style from './style.module.scss';
import { createPortal } from 'react-dom';
import { useOutside } from '../../lib/utils/hooks/useOutside';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';
import { ReactComponent as CloseSVG } from '../../images/close.svg';
import { IconButton } from '../iconButton/iconButton';

interface ScaleImageProps {
  images: Image[],
  activeImage: number,
  visible: boolean,
  onClose: () => void
}

export const ScaleImage = ({ images, activeImage, onClose, visible }: ScaleImageProps) => {
	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [visible]);

	const ref = useOutside(() => onClose?.(), { capturing: true });
	const [currentImage, setCurrentImage] = useState();

	useEffect(() => setCurrentImage(activeImage as SetStateAction<any>), [activeImage]);

	const changeCurrentImage = (payload: any) => setCurrentImage(payload?.id);

	return createPortal(
		<ToggleVisibility visible={ visible }>
			<div className={ style.overlay }>
				<IconButton icon={ CloseSVG } className={ style.close } onClick={ onClose } size='small'/>
				<div className={ style.scaleImage }>
					<ImagesSlider ref={ ref } activeImageID={ currentImage } onChoose={ changeCurrentImage } images={ images } largeImageClassName={ style.mainImage } position='horizontal'/>
				</div>
			</div>
		</ToggleVisibility>
	  , document.body
	);
};
