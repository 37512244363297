import { FetchStatus as FetchStatusEnum } from '../../lib/enums/fetchStatuses';
import { FetchErrorBlock } from '../notFoundBlock/notFoundBlock';
import { LoaderLine } from '../loaderLine/loaderLine';

interface IFetchStatus {
  children: JSX.Element | JSX.Element[],
  status: FetchStatusEnum,
  error: string
}

export const FetchResponse = ({ status, children, error }: IFetchStatus) => {
	switch (status) {
	case FetchStatusEnum.NOT_FOUND:
		return <FetchErrorBlock title='Увы! Мы не смогли ничего найти' description={ error } visibility />;
	case FetchStatusEnum.PENDING:
		return <LoaderLine visibility />;
	default:
		return <>{ children }</>;
	}
};
