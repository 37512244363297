interface TabsProps {
  children: JSX.Element | JSX.Element[] | string | number,
  value: string | number
}

export const Tab = ({ children, value }: TabsProps): JSX.Element => {
	return (
		<div data-aim='tab'>
			{ children }
		</div>
	);
};
