import { request } from '../../request';

interface UserUnsecure {
  email: string,
  password: string,
  fingerprint: string
}

interface UserSecure {
  phone: string
}

interface SignInRequest {
  user: UserUnsecure
}

interface signUpRequest {
  user: UserUnsecure & UserSecure
}

export const signIn = (data: SignInRequest) => {
	return request({ url: '/user/signin', method: 'POST', data });
};

export const signUp = (data: signUpRequest) => {
	return request({ url: '/user/signup', method: 'POST', data });
};
