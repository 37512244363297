import { SectionTitle } from '../sectionTitle/sectionTitle';
import style from './style.module.scss';
import cs from 'classnames';

interface SectionProps {
  title: string,
  children: JSX.Element | JSX.Element[],
  className?: string
}

export const Section = ({ title, children, className }: SectionProps): JSX.Element => {
	return (
		<div className={ cs(style.section, className) }>
			<SectionTitle className={ style.title }>{ title }</SectionTitle>
			{ children }
		</div>
	);
};

Section.defaultProps = {
	className: null
};
