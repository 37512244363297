import { Typography } from '../../components/typography/typography';
import { Input } from '../../components/input/input';
import { Button } from '../../components/button/button';
import { Link } from '../../components/link/link';
import style from './style.module.scss';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { RegExp } from '../../lib/enums/regExp';
import { useAuth } from '../../lib/utils/hooks/useAuth';
import { useRecoilState } from 'recoil';
import { isLoggedAtom } from '../../recoil/atoms';
import { useForm } from 'react-awesome-form';
import { signUp } from '../../lib/api';

export const Reg = (): JSX.Element => {
	const history = useHistory();
	const authUser = useAuth();
	const [, setLogged] = useRecoilState(isLoggedAtom);

	const { addInput, validate: validateForm, errors } = useForm();

	const email = addInput('email', false, '', { required: true, regExp: RegExp.EMAIL });
	const mobile = addInput('mobile', false, '', { required: true, regExp: RegExp.MOBILE });
	const password = addInput('password', false, '', { required: true });

	const passwordRepeat = useRef<string>();
	const [passwordRepeatWrong, setPasswordRepeatWrong] = useState<boolean>();

	const inputPasswordRepeat = (e: any) => {
		passwordRepeat.current = e.target.value;
		setPasswordRepeatWrong(false);
	};

	const handlePasswordRepeatBlur = (e: any) => {
	  if (e.target?.value.length === 0 || e.target.value !== password.value()) {
			setPasswordRepeatWrong(true);
		}
	};

	const handleSignUp = () => {
		validateForm();
	  	if (passwordRepeatWrong || errors.length > 0) return null;

	  	signUp({
			user: {
				phone: `+${mobile.value()}`,
				email: email.value(),
				password: password.value(),
				fingerprint: window.navigator.userAgent
			}
		}).then((res) => {
			if (res.data) {
				const { user: { JWT } } = res.data || {};

				authUser(JWT).then(() => {
					setLogged(true);
					history.push('/profile');
				});
			}
		});
	};

	return (
		<main className={ style.reg }>
			<form className={ style.regForm }>
				<Typography type='h3' tag='h1'>Регистрация</Typography>
				<Input { ...email.handlers } error={ email.error } className={ style.field } errorMsg='Неправильная почта' type='text' placeholder='Email' />
				<Input { ...mobile.handlers } error={ mobile.error } className={ style.field } errorMsg='Неправильный номер телефона' type='text' placeholder='Телефон' />
				<Input { ...password.handlers } className={ style.field } error={ password.error } type='password' placeholder='Пароль' />
				<Input className={ style.field } onInput={ inputPasswordRepeat } onBlur={ handlePasswordRepeatBlur } error={ passwordRepeatWrong } errorMsg='Пожалуйста, повторите пароль' type='password' placeholder='Повтор пароля' />
				<Button onClick={ handleSignUp } className={ style.button } theme='primary'>Регистрация</Button>
				<Link className={ style.link } href='/signIn' type='inner' theme='secondary' size='medium'>Войти</Link>
			</form>
		</main>
	);
};
