import {
	useEffect,
	useState,
	MutableRefObject,
	useCallback,
	RefObject
} from 'react';

interface IRect {
	left: number;
	right: number;
	bottom: number;
	top: number;
	width: number;
	height: number;
	x: number;
	y: number;
}

const initial = {
	left: 0,
	right: 0,
	bottom: 0,
	top: 0,
	width: 0,
	height: 0,
	x: 0,
	y: 0
};

export const useRect = (ref: MutableRefObject<any> | RefObject<any>): IRect => {
	const [rect, setRect] = useState<IRect>(initial);

	const getRect = useCallback((): IRect => {
		return ref.current?.getBoundingClientRect();
	}, [ref.current]);

	const update = (): void => setRect(getRect());

	useEffect(() => {
		if (!ref?.current) return;

		update();

		document.addEventListener('resize', update);

		return () => document.removeEventListener('resize', update);
	}, [ref.current]);

	useEffect(() => {
		update();
	}, []);

	return rect;
};
