import { useRecoilState } from 'recoil';
import { isMobileAtom } from '../../recoil/atoms';
import { DesktopHeader } from './desktop/header';
import { MobileHeader } from './mobile/header';
import { useRect } from '../../lib/utils/hooks/useRect';
import { useEffect, useRef, useState } from 'react';

export const Header = (props: any): JSX.Element => {
	const [isMobile] = useRecoilState(isMobileAtom);
	const headerRef = useRef();
	const headerRect = useRect(headerRef);
	const [headerFix, setHeaderFix] = useState(false);

	useEffect(() => {
		const headerListener = () => setHeaderFix((!!headerRect?.height && pageYOffset > headerRect?.height) ?? false);

		window.addEventListener('scroll', headerListener);

		return () => window.removeEventListener('scroll', headerListener);
	}, [headerRect]);

	if (isMobile) return <MobileHeader { ...props } fixed={ headerFix } ref={ headerRef } />;

	return <DesktopHeader { ...props } fixed={ headerFix } ref={ headerRef } />;
};
