import { request } from '../request';

interface ICreateReview {
  review: {
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    text: string,
    rating: number
  }
}

interface IReviewsFetch {
  count: number,
  offset: number
}

export const reviewsFetch = (query: IReviewsFetch) => {
	return request({ url: '/reviews', params: query });
};

export const createReview = (data: ICreateReview) => {
	return request({ url: '/reviews', method: 'POST', data });
};
