import { useState } from 'react';

interface UseHeaderReturn {
  closeMenuVisibility: () => void,
  handleMenuVisibility: () => void,
  isVisibleMenu: boolean
}

export const useHeader = (): UseHeaderReturn => {
	const [isVisibleMenu, toggleMenuVisibility] = useState(false);

	const handleMenuVisibility = (): void => toggleMenuVisibility(prevState => !prevState);
	const closeMenuVisibility = (): void => toggleMenuVisibility(false);

	return { isVisibleMenu, handleMenuVisibility, closeMenuVisibility };
};
