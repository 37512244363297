import { lastItemsFetch } from '../../lib/api/rest/shop/items';
import { useEffect, useState } from 'react';
import { useFetchStatus } from '../../lib/utils/hooks/useFetchStatus';
import { FetchStatus } from '../../lib/enums/fetchStatuses';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';
import { ItemsGroup } from '../../components/itemGroup/itemGroup';

interface LastItemsContainerProps {
  className: string
}

export const LastItemsContainer = ({ className }: LastItemsContainerProps) => {
	const [lastItems, setLastItems] = useState([]);
	const [fetchStatus, setFetchStatus] = useFetchStatus();

	useEffect(() => {
		lastItemsFetch({ count: 5 }).then((res) => {
			const { items } = res.data;

			setLastItems(items);
			setFetchStatus(items ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);
		}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
	}, []);

	return (
	  <FetchResponse status={ fetchStatus } error='Ошибка при загрузке новых товаров'>
			<ItemsGroup className={ className } items={ lastItems } />
	  </FetchResponse>
	);
};
