import style from './style.module.scss';
import { Typography } from '../typography/typography';
import { ButtonLink } from '../buttonLink/buttonLink';

export interface OrderCardProps {
    id: string,
    price: number
    createdAt: string
}

export const OrderCard = ({ id, createdAt, price }: OrderCardProps) => {
	return (
		<div className={ style.orderCard }>
			<Typography type='h3' tag='span' className={ style.title }>{ `${price} ₽` }</Typography>
			<Typography type='body' className={ style.subtitle }>{ new Date(createdAt).toLocaleString() }</Typography>
			<ButtonLink theme='primary' type='inner' href={ `/order/${id}` }>Просмотр</ButtonLink>
		</div>
	);
};