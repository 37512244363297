import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';

export interface IconSize {
  size?: 'medium' | 'small' | 'slt' | 'large'
}

interface IconProps extends IconSize {
  image: any,
  className?: string
}

export const Icon = ({ image: Image, size, className }: IconProps): JSX.Element => {
	return <Image className={ cn(style.icon, className) } data-size={ size } />;
};

Icon.defaultProps = {
	className: null
};
