import style from './style.module.scss';
import { OrderCard } from '../orderCard/orderCard';
import cs from 'classnames';

type Orders = {
    id: string,
    created_at: string,
    totalprice: number
}

interface OrderCardGroupProps {
    orders: Orders[],
    className?: string
}

export const OrderCardGroup = ({ orders, className }: OrderCardGroupProps) => {
	return (
	    <div className={ cs(style.orderCardGroup, className) }>
			{
				orders?.map(({ id, created_at, totalprice }) => {
					return <OrderCard key={ id } id={ id } price={ totalprice } createdAt={ created_at } />;
				})
			}
		</div>
	);
};

OrderCardGroup.defaultProps = {
	className: undefined
};