import style from './style.module.scss';
import cs from 'classnames';

type Value = string | number;

interface InputRadioProps {
  value: Value,
  currentValue: string | number | undefined | null,
  className?: string,
  onChange: (value: any) => void
}

export const InputRadio = ({ value, currentValue, className, onChange }: InputRadioProps): JSX.Element => {
	const handleClick = () => onChange(value);
	const handleKeyBoard = () => onChange(value);

	return (
		<div onClick={ handleClick } onKeyDown={ handleKeyBoard } tabIndex={ 0 } role='button' className={ cs(style.inputRadio, className, { [style.active]: currentValue === value }) } />
	);
};

InputRadio.defaultProps = {
	className: null
};
