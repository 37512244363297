import style from './style.module.scss';
import cs from 'classnames';

interface MainTemplateProps {
    children: JSX.Element | JSX.Element[],
    className?: string
}

export const MainTemplate = ({ children, className }: MainTemplateProps) => {
	return (
		<main className={ cs(style.main, className) }>
			{ children }
		</main>
	);
};

MainTemplate.defaultProps = {
	className: undefined
};