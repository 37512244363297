import style from './style.module.scss';
import { InputRadio } from '../../components/inputRadio/inputRadio';
import { useState } from 'react';
import { Tab } from '../../components/tab/tab';
import { Typography } from '../../components/typography/typography';
import { Tabs } from '../../components/tabs/tabs';
import { ButtonLink } from '../../components/buttonLink/buttonLink';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Policy = () => {
	const [policyType, setPolicyType] = useState<number>(1);

	return (
		<MainTemplate>
			<div className={ style.policyOptions }>
				<div className={ style.policyType }>
          Политика конфиденциальности
					<InputRadio onChange={ setPolicyType } className={ style.policyOption } value={ 1 } currentValue={ policyType } />
				</div>
				<div className={ style.policyType }>
          Пользовательское соглашение
					<InputRadio onChange={ setPolicyType } className={ style.policyOption } value={ 2 } currentValue={ policyType } />
				</div>
				<div className={ style.policyType }>
          Политика обработки персональных данных
					<InputRadio onChange={ setPolicyType } className={ style.policyOption } value={ 3 } currentValue={ policyType } />
				</div>
			</div>
			<Tabs className={ style.policyBlock } currentValue={ policyType }>
				<Tab value={ 1 }>
					<Typography type='h4' tag='h2' className={ style.sectionTitle }>Политика конфиденциальности</Typography>
					<ButtonLink className={ style.policyBlock } type='outer' href='/files/policy/conf.pdf' theme='secondary' target="_blank" download>Скачать PDF</ButtonLink>
				</Tab>
				<Tab value={ 2 }>
					<Typography type='h4' tag='h2' className={ style.sectionTitle }>Пользовательское соглашение</Typography>
					<ButtonLink className={ style.policyBlock } type='outer' href='/files/policy/user.pdf' theme='secondary' target="_blank" download>Скачать PDF</ButtonLink>
				</Tab>
				<Tab value={ 3 }>
					<Typography type='h4' tag='h2' className={ style.sectionTitle }>Политика обработки персональных данных</Typography>
					<ButtonLink className={ style.policyBlock } type='outer' href='/files/policy/data.pdf' theme='secondary' target="_blank" download>Скачать PDF</ButtonLink>
				</Tab>
			</Tabs>
		</MainTemplate>
	);
};
