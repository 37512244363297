import style from './style.module.scss';
import { Subcategory } from '../../lib/enums/responses';
import { NavLink } from '../navLink/navLink';
import cs from 'classnames';
import { columnArray } from '../../lib/utils/chunkArray';

interface SubcategoriesGroupProps {
  subcategories: Subcategory[],
  onLinkClick: () => void,
  linkClassName?: string
}

export const SubcategoriesGroup = ({ subcategories, onLinkClick, linkClassName }: SubcategoriesGroupProps): JSX.Element => {
	return (
		<>
			{ columnArray(subcategories, 2)?.map((col: any[], index) => {
				return (
					<nav key={ index } className={ style.subcategoryCol }>
						{
							col?.map((subcategory) => {
								const { text, categoryID, highlight } = subcategory;

								return (
									<NavLink className={ cs(style.subcategoryLink, linkClassName, { [style.highlight]: highlight }) } key={ categoryID } to={ `/items/${categoryID}` } label={ text } onClick={ onLinkClick }/>
								);
							})
						}
					</nav>
				);
			}) }
		</>
	);
};

SubcategoriesGroup.defaultProps = {
	linkClassName: undefined
};
