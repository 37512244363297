import { useEffect, useState } from 'react';
import { meOrdersFetch } from '../../lib/api/rest/profile/order';
import { OrderCardGroup } from '../../components/orderCardGroup/orderCardGroup';
import { isLoggedAtom } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { orderCountAtom } from '../../recoil/atoms/ordersCounts';

interface OrdersContainerProps {
    className?: string,
	page: number,
	count: number
}

export const OrdersContainer = ({ className, page, count }: OrdersContainerProps) => {
	const [isLogged] = useRecoilState(isLoggedAtom);
	const [, setOrdersCount] = useRecoilState(orderCountAtom);
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		isLogged && meOrdersFetch({ count, offset: (page - 1) * count }).then((res) => {
		   	const { orders, count } = res.data || {};

			setOrders(orders);
			setOrdersCount(count);
		});
	}, [isLogged, count, page]);

	return <OrderCardGroup className={ className } orders={ orders } />;
};

OrdersContainer.defaultProps = {
	className: undefined
};