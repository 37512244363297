import { Modal } from '../../modal/modal';
import { Typography } from '../../typography/typography';
import style from './style.module.scss';
import { Button } from '../../button/button';

interface ModalReviewProps {
  text: string,
  id: string | undefined,
  onDelete: (id: string | undefined) => void,
  onApprove: (id: string | undefined) => void,
  onClose: () => void,
  visible: boolean
}

export const ModalReview = ({ onApprove, onDelete, text, visible, onClose, id }: ModalReviewProps): JSX.Element => {
	const handleDeleteClick = () => onDelete(id);
	const handleApproveClick = () => onApprove(id);

	return (
		<Modal onClose={ onClose } className={ style.modalReview } title='Отзыв' subtitle='Управление' visible={ visible }>
			<Typography type='h5' className={ style.title }>Текст отзыва</Typography>
			<Typography type='body'>{ text }</Typography>
			<div className={ style.modalButtons }>
				<Button theme='primary' className={ style.button } onClick={ handleApproveClick }>Одобрить</Button>
				<Button theme='primary' className={ style.button } onClick={ handleDeleteClick }>Удалить</Button>
			</div>
		</Modal>
	);
};
