import { withRouter } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export const ScrollToTop = withRouter(({ history }) => {
	const prevHistory = useRef<string>('');

	useEffect(() => {
	  const listen = history.listen((history) => {
		  if (prevHistory.current !== history.pathname) {
			  window.scrollTo(0, 0);
		  }

		  prevHistory.current = history.pathname;
	  });

	  return () => listen();
	}, []);

	return null;
});
