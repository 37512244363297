import style from './style.module.scss';
import { Section } from '../../components/section/section';
import { Input } from '../../components/input/input';
import { Textarea } from '../../components/textarea/textarea';
import { Button } from '../../components/button/button';
import { Rate } from '../../components/rate/rate';
import { Pagination } from '../../components/pagination/pagination';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { reviewsCount } from '../../recoil/atoms';
import { usePagination } from '../../lib/utils/hooks/usePagination';
import { useForm } from 'react-awesome-form';
import { RegExp } from '../../lib/enums/regExp';
import { createReview } from '../../lib/api/rest/reviews';
import { ReviewsContainer } from '../../containers/reviewsContainer/reviewsContainer';
import { ImagesSlider } from '../../components/imagesSlider/imagesSlider';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';
import { useURL } from '../../lib/utils/hooks/useURL';
import { useHistory, useLocation } from 'react-router';

import review1 from '../../images/reviews/1.jpg';
import review2 from '../../images/reviews/2.jpg';
import review3 from '../../images/reviews/3.jpg';
import review4 from '../../images/reviews/4.jpg';
import review5 from '../../images/reviews/5.jpg';
import review6 from '../../images/reviews/6.jpg';
import review7 from '../../images/reviews/7.jpg';
import review8 from '../../images/reviews/8.jpg';
import review9 from '../../images/reviews/9.jpg';
import review10 from '../../images/reviews/10.jpg';
import review11 from '../../images/reviews/11.jpg';
import review12 from '../../images/reviews/12.jpg';
import review13 from '../../images/reviews/13.jpg';
import review14 from '../../images/reviews/14.jpg';
import review15 from '../../images/reviews/15.jpg';
import { ScaleImage } from '../../components/scaleImage/scaleImage';

export const Reviews = (): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const [reviewActiveImage, setReviewImage] = useState(0);
	const [scaleVisibleModal, setVisibleScaleModal] = useState(false);

	const toggleVisible = () => setVisibleScaleModal(prevState => !prevState);

	useEffect(() => {
		console.log(reviewActiveImage);
	}, [reviewActiveImage]);

	const [getReviewsCount] = useRecoilState(reviewsCount);

	const { getParams, addParam } = useURL(location.search, history);
	const { page } = getParams() || {};

	const { addInput, validate: validateForm, errors } = useForm();

	const { pageCount } = usePagination(10, getReviewsCount, 1);
	const [rate, setRate] = useState<number>(0);

	const formRef = useRef() as MutableRefObject<any>;

	const firstName = addInput('firstName', false,'', {
		required: true,
		regExp: RegExp.NAME
	});

	const lastName = addInput('lastName', false, '', {
		required: true,
		regExp: RegExp.NAME
	});

	const mobile = addInput('mobile', false, '', {
		required: true,
		regExp: RegExp.MOBILE
	});

	const email = addInput('email', false, '', {
		required: true,
		regExp: RegExp.EMAIL
	});

	const reviewText = addInput('reviewText',false, '', {
		required: true,
		regExp: RegExp.EMAIL
	});

	const handleChangeRate = (rate: number) => setRate(rate);

	const sendComment = () => {
		validateForm();

		if (errors.length > 0) return null;

		createReview({
			review: {
				firstname: firstName.value(),
				lastname: lastName.value(),
				phone: `+${mobile.value()}`,
				email: email.value(),
				text: reviewText.value(),
				rating: rate
			}
		}).then(() => {
			formRef.current.reset();

			firstName.resetInput();
			lastName.resetInput();
			mobile.resetInput();
			email.resetInput();
			reviewText.resetInput();

			setRate(0);
		});
	};

	const images = [
		{ image: review1, payload: { id: 0 } },
		{ image: review2, payload: { id: 1 } },
		{ image: review3, payload: { id: 2 } },
		{ image: review4, payload: { id: 3 } },
		{ image: review5, payload: { id: 4 } },
		{ image: review6, payload: { id: 5 } },
		{ image: review7, payload: { id: 6 } },
		{ image: review8, payload: { id: 7 } },
		{ image: review9, payload: { id: 8 } },
		{ image: review10, payload: { id: 9 } },
		{ image: review11, payload: { id: 10 } },
		{ image: review12, payload: { id: 11 } },
		{ image: review13, payload: { id: 12 } },
		{ image: review14, payload: { id: 13 } },
		{ image: review15, payload: { id: 14 } }
	];

	const handleChange = (payload: any) => setReviewImage(payload.id);

	return (
		<MainTemplate>
			<ScaleImage images={ images } activeImage={ reviewActiveImage } visible={ scaleVisibleModal } onClose={ toggleVisible } />
			<Section title='Отзывы с других платформ'>
				<ImagesSlider activeImageID={ reviewActiveImage } onChoose={ handleChange } onClick={ toggleVisible } images={ images }/>
			</Section>
			<Section title='Отзывы' className={ style.reviewsBlock }>
				<ReviewsContainer page={ +page } count={ 10 } className={ style.reviewsGroup } />
				<Pagination onChange={ addParam('page') } currentPage={ +page } defaultPage={ 1 } pageCount={ pageCount } />
			</Section>
			<form ref={ formRef }>
				<div className={ style.reviewFields }>
					<Input { ...firstName.handlers } placeholder='Имя' type='text' className={ style.reviewField } error={ firstName.error } errorMsg='Пожалуйста, укажите корректное имя' />
					<Input { ...lastName.handlers } placeholder='Фамилия' type='text' className={ style.reviewField } error={ lastName.error } errorMsg='Пожалуйста, укажите корректную фамилию' />
					<Input { ...mobile.handlers } placeholder='Телефон' type='text' className={ style.reviewField } error={ mobile.error } errorMsg='Пожалуйста, укажите корректный номер телефона'/>
					<Input { ...email.handlers } placeholder='Email' type='email' className={ style.reviewField } error={ email.error } errorMsg='Пожалуйста, укажите корректный email' />
				</div>
				<Rate onChange={ handleChangeRate } className={ style.starField } currentRate={ rate } maxRate={ 5 } size='large' interactive />
				<Textarea { ...reviewText.handlers } placeholder='Отзыв' className={ style.reviewTextField } />
				<Button theme='primary' onClick={ sendComment } className={ style.reviewButton }>Отправить</Button>
			</form>
		</MainTemplate>
	);
};
