import { useRef, useState, Children, MutableRefObject } from 'react';
import { SelectField } from './selectField/selectField';
import { SelectList } from './selectList/selectList';
import style from './style.module.scss';
import { useOutside } from '../../lib/utils/hooks/useOutside';
import cs from 'classnames';
import { isKeyboardPress } from '../../lib/utils/isKeyboardPress';

export type IValue = string | string[] | number | number[] | null;

interface SelectProps {
  children: JSX.Element | JSX.Element[] | undefined,
  onChange: (value: IValue | undefined) => void,
  className?: string,
  closeOnSelect?: boolean,
  value: IValue | undefined,
  placeholder: string,
  multiple?: boolean
}

export const Select = ({ children, value, onChange, closeOnSelect, placeholder, multiple, className }: SelectProps): JSX.Element => {
	const [selectVisibility, setSelectVisibility] = useState<boolean>(false);
	const text = useRef<string | null>(null);
	const selectRef = useOutside(() => setSelectVisibility(false));

	Children.forEach(children, (elem) => {
	  if (elem && typeof elem.props.children === 'object') {
	    const children = elem.props.children;

	    Children.forEach(children, (elem) => {
				if (elem.props.value === value) text.current = elem.props.children;
			});
		}

		if (elem && elem.props.value === value) text.current = elem.props.children;
	});

	if (value == null) text.current = null;

	const toggleSelect = () => setSelectVisibility(prevState => !prevState);
	const handleSelectClick = () => toggleSelect();
	const handleSelectKeyDown = (e: any) => isKeyboardPress(e) && toggleSelect();

	const handleKeyBoardOption = (chosenValue: any, e: any) => {
		if (isKeyboardPress(e)) {
			if (chosenValue === value) {
				onChange(null);
			} else {
				onChange(chosenValue);
			}

			closeOnSelect && setSelectVisibility(false);
		}
	};

	const handleClickOption = (chosenValue: any) => {
		const val = Array.isArray(value) ? [value].flat().concat(chosenValue) : [chosenValue];

		const setValue = multiple ? [...new Set(val)] : chosenValue;

		if (chosenValue === value) {
			onChange(null);
		} else if (multiple && value && Array.isArray(value) && value.includes(chosenValue as never)) {
			// @ts-ignore
			const newValuesArray = value.filter((value: any) => value !== chosenValue);

		  onChange(newValuesArray);
		} else {
			onChange(setValue);
		}

		closeOnSelect && setSelectVisibility(false);
	};

	return (
		<div className={ cs(style.select, className) } ref={ selectRef as MutableRefObject<any> }>
			<SelectField placeholder={ placeholder } onKeyDown={ handleSelectKeyDown } onClick={ handleSelectClick }>
				{ multiple && Array.isArray(value) && value.length > 0 ? `Выбрано: ${value.length}` : text.current }
			</SelectField>
			{ selectVisibility &&
        <SelectList multipleButton={ multiple } value={ value } onChange={ handleClickOption } onChangeKeyBoard={ handleKeyBoardOption }>
        	{ children }
        </SelectList>
			}
		</div>
	);
};

Select.defaultProps = {
	closeOnSelect: false,
	multiple: false,
	className: null
};
