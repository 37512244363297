import { useRecoilState } from 'recoil';
import { userAtom } from '../../../recoil/atoms';
import { meFetch, setAuthToken } from '../../api';
import { normalizeMobile } from '../normilizeMobile';

export const useAuth = () => {
	const [, setUserData] = useRecoilState(userAtom);

	return (JWT: string) => new Promise(((resolve, reject) => {
		try {
			JWT && localStorage.setItem('JWT', JWT);
			setAuthToken(JWT);

			JWT && meFetch().then((res) => {
				const { user: { email, phone, orderAutocomplete: { flat, street, house } } } = res.data;

				setUserData({
					mobile: normalizeMobile(phone),
					email, house, street, flat
				});
			});

			resolve(true);
		} catch (e) {
			reject(false);
		}
	}));
};
