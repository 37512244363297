import style from './style.module.scss';
import { cloneElement, Children } from 'react';
import cs from 'classnames';

interface TabsProps {
  children: JSX.Element | JSX.Element[] | undefined,
  currentValue: number | string | undefined,
  className?: string
}

export const Tabs = ({ children, currentValue, className }: TabsProps): JSX.Element => {
	const tabs = Children.map(children, (child) => {
	  return child && (currentValue === child.props.value && cloneElement(child));
	});

	return (
		<div className={ cs(style.tabs, className) } data-aim='tabs'>
			{ tabs }
		</div>
	);
};

Tabs.defaultProps = {
	className: null
};
