import { useState } from 'react';

export const useSearch = () => {
	const [visibleSearch, setSearchVisibility] = useState<boolean>(false);

	const toggleSearchVisibility = () => {
		setSearchVisibility(prevState => !prevState);
	};
	const closeSearch = () => {
		setSearchVisibility(false);
	};

	return { toggleSearchVisibility, visibleSearch, closeSearch };
};
