import { useEffect, useState } from 'react';
import { IUserResponse, usersFetch } from '../../../lib/api/rest/admin/users';
import { useRecoilState } from 'recoil';
import { usePagination } from '../../../lib/utils/hooks/usePagination';
import { AdminTable } from '../adminTable/adminTable';
import { isLoggedAtom } from '../../../recoil/atoms';
import { useHistory, useLocation } from 'react-router';
import { useURL } from '../../../lib/utils/hooks/useURL';

export const UsersAdminTable = () => {
	const { search } = useLocation();
	const history = useHistory();

	const [isLogged] = useRecoilState(isLoggedAtom);
	const [usersCount, setUsersCount] = useState(0);
	const [users, setUsers] = useState([]);
	const { pageCount, filter } = usePagination(10, usersCount, 1);

	const { getParams, addParam } = useURL(search, history);

	useEffect(() => {
		addParam('page')(1);
	}, []);

	const { page } = getParams() || {};

	const handleChangePage = (page: number) => addParam('page')(page);

	useEffect(() => {
		isLogged && usersFetch({ count: filter, offset: (page as number - 1) * filter }).then((res) => {
			const { users, count } = res?.data || {};

			const usersArray = users?.map((user: IUserResponse, index: number) => {
				const { email, phone, orderAutocomplete: { street, flat, house } } = user;

				return {
				  id: index,
					data: [
						{
							title: 'Email',
							label: email
						},
						{
							title: 'Телефон',
							label: phone
						},
						{
							title: 'Улица',
							label: street
						},
						{
							title: 'Дом',
							label: house
						},
						{
							title: 'Квартира',
							label: flat
						}
					]
				};
			});

			setUsersCount(count);
			setUsers(usersArray);
		});
	}, [isLogged, +page]);

	const columns = ['Email', 'Телефон', 'Улица', 'Дом', 'Квартира'];

	return <AdminTable data={ users } columns={ columns } handleChangePage={ handleChangePage } page={ +page } pageCount={ pageCount } />;
};

UsersAdminTable.defaultProps = {
	page: 1,
	onChoosePage: undefined,
	pageCount: 0
};
