import { Route } from 'react-router-dom';
import { RouteProps, Redirect } from 'react-router';
import { useRecoilState } from 'recoil';
import { isLoggedAtom } from '../../recoil/atoms';

interface PrivateRouteProps extends RouteProps {
  children: JSX.Element | JSX.Element[]
}

export const PrivateRoute = ({ children, ...rest }: PrivateRouteProps): JSX.Element => {
	return (
		<Route { ...rest }>
			{ localStorage.getItem('JWT') ? children : <Redirect to='/signin' /> }
		</Route>
	);
};
